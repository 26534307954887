import * as React from "react";
import { Layout, LayoutDescription, LayoutHeader, LayoutImage, LayoutTitle, NumberedCard, NumberedList, NumberedListCaption } from "@brainfinance/icash-component-library";
import { generateTitle } from "@helpers/generateTitle";
import { LayoutProps } from "@types";
import { STEPS } from "./numbered-list-data";
// Images
import placeHolderImg from "@images/placeholder.png";

export const NumberedListSection = (props:LayoutProps) => (
  <Layout className="mb-0">
    <LayoutHeader>
      <LayoutTitle>
        {generateTitle({
          title: props.title,
          gray: props.gray,
        })}
      </LayoutTitle>
      <LayoutDescription>{props.description}</LayoutDescription>
      <LayoutImage src={props.src} placement="sm-left" />
    </LayoutHeader>
    <NumberedList>
      <div className="numbered-list--box">
        {STEPS.map((step, ndx) => (
          <NumberedCard
            key={ndx}
            number={step.number}
            title={step.title}
            description={step.description}
          />
        ))}
        <img
          className="numbered-list--img"
          src={placeHolderImg}
          alt="List img"
        />
      </div>
      <NumberedListCaption>
        Find out how we differentiate from other lenders with this easy-to-read{" "}
        <a className="global--link" href="/">Comparison chart</a>!
      </NumberedListCaption>
    </NumberedList>
  </Layout>
);

NumberedListSection.defaultProps = {
  title: "Why use our loans",
  gray: "loans",
  description:
    "Take care of whatever life throws at you: Unexpected bills, home repair, driving tickets, medical fees, and more.",
  src: placeHolderImg
}
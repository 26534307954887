import * as React from "react";
import { graphql } from "gatsby";
import { getImage } from "gatsby-plugin-image";

import { PageLayout, SEO } from "@components/page-layout";
import { NumberedListSection } from "@containers/numbered-list";
import { GrayPhrase } from "@components/typography";
import { OurCustomers } from "@containers/our-customers";
import { BigListSection } from "@containers/big-list";
import { SideHeader } from "@containers/header";
import { BIGLISTWHYICASH } from "@containers/big-list/big-list-data";
import { BigListItem } from "@brainfinance/icash-component-library";
import { ProvincesSection } from "@containers/provinces";
import { PlansSection } from "@containers/plans";
import { Box } from "@components/box";
// Images
import placeHolderImg from "@images/placeholder.png";

const WhyIcash = (props: any) => (
  <PageLayout>
    <SEO title="Why iCash" />
    <SideHeader
      description="That give you control."
      subParagraph="Instant loans approved in 5 minutes for Canadians."
      src={getImage(props.data.file)?.images.fallback?.src || ""}
      srcSet={getImage(props.data.file)?.images.fallback?.srcSet || ""}
    >
      <GrayPhrase>Best</GrayPhrase> online loans
      <br />
      in canada
    </SideHeader>
    <NumberedListSection />
    <OurCustomers />
    <BigListSection
      title="Why choose an iCash instant loan"
      gray="instant loan"
      description="iCash offers easy, fast, and secure online loans to Canadians, 24 hours a day and 7 days a week. Funds are sent via e-Transfer and received the same day."
      img={placeHolderImg}
      disclaimer="Maximum repayment period 62 days. APR is 109.50%. For a $300 loan of 14 days, the total payback amount is $345. Interest fees: Alberta $15 per 100, British Columbia $15 per 100, Manitoba $17 per 100, New Brunswick $15 per 100, Nova Scotia $19 per 100, Ontario $15 per 100, PEI $15 per 100. 372 Bertha Street, Hawkesbury, Ontario, K6A 2A8. iCASH only offers online services. Please note that we cannot accept customers or conduct any transactions from this location."
    >
      {BIGLISTWHYICASH.map((item, ndx) => (
        <BigListItem key={ndx} {...item} />
      ))}
    </BigListSection>
    <Box className="h-[1px]" />
    <ProvincesSection />
    <PlansSection />
  </PageLayout>
);

export default WhyIcash;

export const pageQuery = graphql`
  query {
    file(relativePath: {eq: "fullscreen-background.jpg"}) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP], width: 1160)
      }
    }
  }
`
export const STEPS = [
  {
    number: 1,
    title: "Getting a quick online loan",
    description:
      "Here at iCASH, we're focused on helping Canadians address their cash flow needs - we pay more attention to your repayment capacity than to your credit score. Don't be concerned about your ability to qualify for online payday loans if you don't have perfect credit history as it's not the only criteria that we look at."
  },
  {
    number: 2,
    title: "No documents required",
    description:
      "Getting an unsecured loan through iCASH is easy. The online loan application process is in real-time, and we have all the tools to evaluate your application without asking for any documents. There's no waiting in line and faxing long forms, so this way you finish fast and get your money faster."
  },
  {
    number: 3,
    title: "No hidden charges",
    description:
      "We are committed to responsible and transparent lending. We make sure all the fees involved in your loan are displayed in a clear and understandable manner so it gives you the option to take an enlightened decision. We are also available to answer every single question you may have. An instant payday loan from iCASH gives you all the tools you need to reach your financial goals."
  },
  {
    number: 4,
    title: "Get approved in just minutes 24/7",
    description:
      "We know that the sooner you get your cash loan, the sooner you go back to the things you care about. We use a sophisticated decision system that renders a decision in just minutes - the lending process is fully automated, so you can be more relaxed knowing that your application will be instantly reviewed and approved."
  },
  {
    number: 5,
    title: "Get an e-Transfer in 2 minutes!",
    description:
      "After having signed your loan agreement, your funds will be sent by e-transfer and you will receive them in less than 2 minutes! Funds are sent 24/7 and even on Holidays!"
  },
  {
    number: 6,
    title: "Earn unlimited cashback",
    description:
      "Our customers have the opportunity to earn a percentage back on the cost of borrowing of every loan paid back! Earn and redeem points; and continue saving like this!"
  }
];
import * as React from "react";
import {
  Layout,
  LayoutDescription,
  LayoutHeader,
  LayoutTitle,
  Plans,
  PlansCard
} from "@brainfinance/icash-component-library";
import { LayoutProps } from "@types";
import { generateTitle } from "@helpers/generateTitle";

export const PlansSection = (props: LayoutProps) => (
  <Layout>
    <LayoutHeader>
      <LayoutTitle>
        {generateTitle({
          title: props.title,
          gray: props.gray,
        })}
      </LayoutTitle>
      <LayoutDescription>{props.description}</LayoutDescription>
    </LayoutHeader>
      <Plans>
        <PlansCard title="Cash advance" isLeft>
          One payment<br /><br />
          With a Cash Advance loan, you can pay back your loan on your next payday in one single payment.
        </PlansCard>
        <div className="plans--or">or</div>
        <PlansCard title="Flexpay">
          Two or three payments<br /><br />
          With the Flexpay*, you pay back your loan in 2 or 3 equal payments* on your next upcoming paydays.This
          product, exclusively offered by iCASH, allows you to have more time to repay your loan while paying no
          additional interest.
        </PlansCard>
        <PlansCard title="Same interest fee" yellow="Same">
          Yes, you heard right!
          <br />
          Whichever repayment plan* you choose, the interest fee will not change!
        </PlansCard>
      </Plans>
  </Layout>
);

PlansSection.defaultProps = {
  title: "Cash advance and flexpay loans",
  gray: "flexpay loans",
  description:
    "We know that everyone's financial situation is different. Get the loan amount and repayment plan* that suits your needs, guaranteed."
};

import * as React from "react";
import { Box } from "@components/box";
import { GatsbyLink } from "@components/gatsby-link";
import { FullscreenLeft, FullscreenRight } from "@brainfinance/icash-component-library";
import backgroundOurCustomers from "@images/our-customers.png";
import logo from "@images/icash-symbol-black-sized.svg";

export const OurCustomers = () => (
  <Box display="flex" className="relative flex-row flex-wrap mb-[5rem] md:mb-[3rem] sm:px-[22px]">
    <FullscreenLeft style={{ backgroundImage: `url(${backgroundOurCustomers})` }} logo={logo}>
      <h2 className="global--heading mt-[2.5rem] sm:mt-[1.75rem]">
        Customers
      </h2>
    </FullscreenLeft>
    <FullscreenRight>
      <h3 className="global--subheading mb-[2rem] sm:mb-[22px]">Our customers</h3>
      <p className="global--paragraph text-interface-300">
        Our users appreciate the fact that we can offer a flexible, convenient and completely secure online lending
        experience to help them get out of debt fast. And we love to hear from our users!
        <br /><br />
        Got something to say?
        <br />
        Tell us about your experience by writing to <GatsbyLink to="/contact-us">Contact Us</GatsbyLink>.
      </p>
    </FullscreenRight>
  </Box>
);
